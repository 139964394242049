import React from "react";
import "./PreContact.css";
import Button from "../../UIKit/Button/Button";
import Modal from "../../UIKit/Modal/Modal";

function PreContact() {

  const scrollToContact = () => {
    window.scrollTo({
      top: 4800,
      behavior: 'smooth'
    });
  };

  return (
    <div className="pre-contact">
      <div className="pre-contact-text">
        Now You Didn't Come This Far
        <br /> To Only Come This Far...
        <div className="pre-contact-buttons">
          <Button onClick={scrollToContact} text="Contact Me" className="preContactButton" />
          <Modal className="preContactButton"/>
        </div>
      </div>
    </div>
  );
}

export default PreContact;
