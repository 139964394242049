import './App.css';
import Welcome from './Pages/Welcome/Welcome'
import About from './Pages/About/About'
import Portfolio from './Pages/Portfolio/Portfolio'
import PreContact from './Pages/PreContact/PreContact'
import Contact from './Pages/Contant/Contact'
import Navbar from './components/Navbar/Navbar'


function App() {

  return (
    <div className="App">
      <Navbar />
      <Welcome/>
      <About/>
      <Portfolio />
      <PreContact />
      <Contact />
    </div>
  );
}


export default App;
