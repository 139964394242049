import React, { useState, useEffect } from "react";
import "./About.css";

function SecondPage() {
  const [appear, setAppear] = useState(false);

  const aboutAppear = () => {
    if (window.scrollY >= 550) {
      setAppear(true);
    } else {
      setAppear(false);
    }
  };

  window.addEventListener("scroll", aboutAppear);

  return (
    <div className="second-page-background">
      <div className="second-page-headerMobile">About me</div>

      <div className="aboutForMobile">
        I’m an organized and motivated{" "}
        <mark class="mark">Frontend developer</mark> with a passion for{" "}
        <mark class="mark">unique UI</mark> and for creating intuitive dynamic
        and <mark class="mark">innovative UX.</mark> I’m seeking a position to{" "}
        <mark class="mark">learn and contribute</mark> through hard work and
        dedication.
      </div>
      <div
        className={appear ? "second-page-content" : "second-page-content-hide"}
      >
        <div className="second-page-header">About me</div>
        <div className="second-page-text">
          <p className="firstLine">
            I’m an organized and motivated{" "}
            <mark class="mark">Frontend developer</mark>{" "}
          </p>
          <p className="secondLine">
            with a passion for <mark class="mark">unique UI</mark> and for
            creating intuitive
          </p>
          <p className="thirdLine">
            dynamic and <mark class="mark">innovative UX.</mark> I’m seeking a
            position to
          </p>
          <p className="forthLine">
            <mark class="mark">learn and contribute</mark> through hard work and
            dedication.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SecondPage;
