import React, { useState } from "react";
import "./Navbar.css";

function Navbar() {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 12) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const scrollToAbout = () => {
    window.scrollTo({
      top: 990,
      behavior: 'smooth'
    });
  };
  const scrollToPortfolio = () => {
    window.scrollTo({
      top: 1978,
      behavior: 'smooth'
    });
  };
  const scrollToPreContact = () => {
    window.scrollTo({
      top: 4070,
      behavior: 'smooth'
    });
  };


  window.addEventListener("scroll", changeBackground);

  return (
    <div className={navbar ? "Navbar active" : "Navbar"}>
      <div className="NavbarContent">
        <div className="NavbarLogoWrapper">
          <div className={navbar ? "NavbarLogoColor" : "NavbarLogo"}></div>
          <div className="NavbarLogoText">SHAFRIRKASHI</div>
        </div>
        <div className="NavbarLinks">
          <li onClick={scrollToAbout}>ABOUT</li>
          <li onClick={scrollToPortfolio}>PORTFOLIO</li>
          <li onClick={scrollToPreContact}>CONTACT ME</li>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
