import React from "react";
import "./Welcome.css";
import Button from "../../UIKit/Button/Button";
import Modal from "../../UIKit/Modal/Modal";

function Firstpage() {

  const scrollToAbout = () => {
    window.scrollTo({
      top: 1078,
      behavior: 'smooth'
    });
  };
  const scrollToPortfolio = () => {
    window.scrollTo({
      top: 960,
      behavior: 'smooth'
    });
  };


  return (
    <div className="Background">
      <div className="firstpageContent">
        <div className="Header">
          <span> Hi, I'm Shafrir</span>
        </div>
        <div className="subHeader">
          <span>
            I'm a Frontend developer with a passion for <br /> beautiful, smart
            and interactive designs
          </span>
        </div>
        <div className="buttons">
        <Modal className="welcomeButton"/>
        </div>
      </div>
    </div>
  );
}

export default Firstpage;
