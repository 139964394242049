import React, { useState } from "react";
import "./Contact.css";
import Snackbar from "../../UIKit/Snackbar/Snackbar";

function Contact() {
  const [linkedin, setLinkedin] = useState(false);

  function copyToClipboard(e) {
    navigator.clipboard.writeText("Shafrirkashi7@gmail.com");
  }

  return (
    <div className="contact-container">
      <div className="contact-text-container">
        <div className="contact-text">
          <div className="contact-header">CONTACT</div>
          <div className="contact-about">
            I'm seeking out opportunities to collaborate with people. I want to
            bring my collective experience and personal view point to the table where
            we can work together to solve real business-problems in a way that
            optimizes all of our respective experience and knowledge.
          </div>
          <div className="contact-name">Shafrir Kashi</div>

          <div
            onMouseOver={() => setLinkedin(false)}
            onMouseOut={() => setLinkedin(true)}
            className="linkedin"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/shafrirk/"
            >
              {linkedin ? "Linkedin" : "linkedin.com/in/shafrirk"}
            </a>
          </div>
          <div className="contact-tel">+972(54)9987133</div>
          <div className="contact-mail" onClick={copyToClipboard}>
            <Snackbar />
          </div>
        </div>
      </div>
      <div className="contact-map-container">
        <div className="contact-map"></div>
      </div>
    </div>
  );
}

export default Contact;
