import React from 'react'
import './Portfolio.css'
import Button from '../../UIKit/Button/Button'

function Portfolio() {
  return (
    <div className='portfolio-background'>
      <div className='portfolio-container'>
        <div className='project1 pcontainer'>
          <div className='project1-header'>Project Alpine</div>
          <div className='project1-sum'>
            WIP E-commerce website inspired by The North Face® & Columbia®
            outdoor clothing and equipment websites.
            <br />
          </div>
          <div className='project1-data'>
            Project was done with React, CSS, HTML.
            <br />
          </div>
          <div className='project1-buttons'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://alpinewebsite.vercel.app/'
            >
              <Button text='Open Project' className='black' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/ShafrirKashi/my-website'
            >
              <Button text='Project Code' className='black' />
            </a>
          </div>
        </div>
        <div className='project2 pcontainer'>
          <div className='project2-header'>Project FLIXNET</div>
          <div className='project2-sum'>
            Movies & TV Series Database website inspired by IMDB.
          </div>
          <div className='project2-data'>
            Project was done with React, CSS, HTML
            <br />
            and using Axios, Context API and Material UI.
          </div>
          <div className='project2-buttons'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://breakingbad-dusky.vercel.app/'
            >
              <Button text='Open Project' className='white' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/ShafrirKashi/BreakingBad'
            >
              <Button text='Project Code' className='white' />
            </a>
          </div>
          <div className='project2Mobile-buttons'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://breakingbad-dusky.vercel.app/'
            >
              <Button text='Open Project' className='black' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/ShafrirKashi/BreakingBad'
            >
              <Button text='Project Code' className='black' />
            </a>
          </div>
        </div>
        <div className='project3 pcontainer'>
          <div className='project3-header'>Project "Biduk Yashir"</div>
          <div className='project3-sum'>
            WIP Mobile website for the IDF <br />
            "Pikud Haoref" together with the Israeli Ministry of Health to
            process, manage and supervise all COVID-19 detection and testing
            across the country.
            <br />
          </div>
          <div className='project3-data'>
            Project was done with React, CSS, HTML and using Axios and Material
            UI.
          </div>
          <div className='project3-buttons'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://covid-jade-eta.vercel.app/'
            >
              <Button text='Open Project' className='black' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/ShafrirKashi/covid'
            >
              <Button text='Project Code' className='black' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio
