import * as React from "react";
import Box from "@mui/material/Box";
import Button from "../../UIKit/Button/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CV from "../../Assets/Img/CV.jpg";
import "./Modal.css";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  height: 200,
  bgcolor: "transparent",
  border: "2px solid #000",
  boxShadow: 524,
  p: 4,
};

export default function BasicModal({className}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="modal-modal">
      <Button className={className} text="Watch My CV" onClick={handleOpen}>
        Open modal
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <a href={CV} target="_blank" rel="noreferrer noopener">
            <img className="modal-img" src={CV} alt="" />
          </a>
        </Box>
      </Modal>
    </div>
  );
}
